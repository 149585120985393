.highlight {
    border: 1px solid #007bff !important;
    color: lightgray;
}

.fontGray {
    color: lightgray !important;
}

.dropzone {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.03);
    height: 200px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.03);
    padding: 23px;
    cursor: pointer;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 20px;
}

.fileInput {
    display: none;
}

.message {
    color: black;
    font-size: large;
    font-weight: 100;
}

.messageDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
