.item {
    margin: 0;
    text-transform: none;
    touch-action: manipulation;
    display: inline-block;
    user-select: none;
    border: 1px solid #f2f4f5;
    text-align: center;
    vertical-align: middle;
    color: #7a8994;
    background-color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
    position: relative;
    flex: 1 1 auto;
    line-height: 20px;
    cursor: pointer;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 11px;
    padding-left: 3px;
    padding-right: 3px;
}

.item.active {
    color: #fff;
    background-color: #007be8;
    border-color: #007be8;
}
