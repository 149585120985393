.container {
    z-index: 99;
    border-radius: 5px;
    overflow: hidden;
    position: fixed;
}

.buttonClose {
    position: absolute;
    top: 55px;
    right: 10px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none rgb(34, 34, 34);
    border-radius: 2px;
    height: 40px;
    width: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 150px;
        height: 100px;
    }
}

@media only screen and (min-width: 600px) {
    .container {
        width: 200px;
        height: 180px;
    }
}

@media only screen and (min-width: 768px) {
    .container {
        width: 300px;
        height: 200px;
        top: 100px;
        left: -140px;
    }
}

@media only screen and (min-width: 992px) {
    .container {
        width: 300px;
        height: 220px;
        top: 85px;
        left: -155px;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        width: 400px;
        height: 300px;
        top: 10px;
        left: -200px;
    }
}

@media only screen and (min-width: 1350px) {
    .container {
        width: 500px;
        height: 300px;
        top: 25px;
        left: -250px;
    }
}

@media all and (display-mode: fullscreen) {
    .container {
        width: 580px;
        height: 450px;
        top: 35px;
        left: -315px;
    }
}
