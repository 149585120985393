.sm {
    width: 70%;
    max-width: 700px;
}

.md {
    width: 778px;
}

.lg {
    width: 900px;
    height: 97%;
}

.xl {
    width: 1280px;
}

.xl2 {
    width: 1536px;
}
