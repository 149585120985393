.remainingBudgetCellActive {
    animation-name: bugdetChange;
    animation-duration: 3s;
}

@keyframes bugdetChange {
    from {
        background-color: #e4eca9;
    }
    to {
        background-color: transparent;
    }
}
