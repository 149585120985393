@tailwind base;
@tailwind components;
@tailwind utilities;

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

/* Extra */
.backdrop {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}
/* Grouping special classes */
.link {
    @apply text-blue-400 hover:text-blue-700 cursor-pointer;
}
.linkLeft {
    @apply flex justify-between content-center font-light text-sm cursor-pointer my-2 text-gray-600 hover:text-black;
}
.badge {
    @apply px-2 py-1 text-xs rounded-sm bg-gray-100;
}
.badge.white {
    @apply bg-white;
}
.workingArea {
    @apply relative mx-auto w-full md:w-10/12 pl-16 md:pl-0;
}
.module {
    @apply relative rounded bg-white shadow-sm p-4 transition-shadow duration-300 hover:shadow-lg;
}
.tabCampaignContainer {
    @apply z-20  p-2 rounded-xl bg-gray-200 bg-opacity-50;
}
.tabCampaignContainer.top {
    @apply absolute inset-x-0 top-0 rounded-t-none;
}
.tabCampaignContainer.bottom {
    @apply absolute inset-x-0 bottom-1 ml-16 h-20 rounded-b-none transition-all duration-300 hover:h-40;
}
.tabCampaign {
    @apply rounded-full flex justify-start p-1 px-2 text-xs ml-1 mr-1 cursor-pointer hover:bg-white hover:text-blue-400 transition-colors duration-300;
}
.tabCampaign.active {
    @apply font-semibold bg-blue-500 text-white;
}
.tabCampaign.active.button {
    @apply font-semibold bg-blue-500 text-white mr-8;
}

/* TableDragSelection */
.table-drag-select td.cell-enabled {
    @apply bg-gray-50 cursor-pointer hover:bg-blue-200 transition-colors duration-100;
}
.table-drag-select td.cell-disabled {
    @apply bg-gray-50;
}
.table-drag-select td.cell-selected {
    @apply bg-blue-500;
}

.table-drag-select td.cell-beign-selected {
    @apply bg-blue-200;
}
.react-calendar,
react-daterange-picker__calendar {
    @apply rounded border-0 border-opacity-10;
}
.react-daterange-picker__wrapper {
    @apply border border-gray-200 rounded-md shadow;
}
.react-daterange-picker__wrapper {
    @apply p-1 border-gray-200;
}
.react-daterange-picker__inputGroup input {
    @apply px-1 rounded focus:ring-0;
}
.react-daterange-picker__range-divider {
    @apply text-black capitalize;
}
.react-daterange-picker__calendar--open {
    @apply rounded border-gray-200 shadow-md;
}
.react-calendar__navigation,
.react-calendar__navigation__label__labelText {
    @apply bg-gray-50 capitalize px-2 rounded-sm;
}
.react-calendar__tile
    .react-calendar__tile--now
    .react-calendar__month-view__days__day {
    @apply bg-gray-100;
}
.buttonNew {
    @apply w-8 hover:w-40 h-8 focus:outline-none overflow-hidden bg-green-500 hover:bg-blue-500 truncate transition-all duration-150 ease-in text-white flex justify-center items-center py-2 px-2 rounded-full cursor-pointer;
}
.buttonEdit {
    @apply w-6 hover:w-32 h-6 focus:outline-none overflow-hidden bg-blue-500 hover:bg-green-500 truncate transition-all duration-150 ease-in text-white flex justify-center items-center py-2 px-2 rounded-full cursor-pointer;
}
.tabActive {
    @apply text-xs font-semibold text-blue-500 border-b-2 border-blue-500 bg-white rounded-t-md shadow;
}
.tabActiveV {
    @apply text-xs text-right font-semibold text-blue-500 border-r-2 border-blue-500 bg-white rounded-l-md shadow;
}
.tabGeneral {
    @apply text-xs text-gray-600 py-2 font-light px-6 block hover:text-blue-500 focus:outline-none;
}
.css-2b097c-container {
    @apply w-full;
}
.css-b8ldur-Input {
    @apply focus:ring-0;
}
.css-yk16xz-control {
    @apply border border-gray-200 rounded-md shadow;
}

/** Date Range Picker **/
.react-daterange-picker {
    display: inline-flex;
    position: relative;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-daterange-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}
.react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: thin solid gray;
}
.react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}
.react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
    cursor: pointer;
}
.react-daterange-picker__button:enabled:hover
    .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: #0078d7;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
    stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
    display: inherit;
}
.react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}
.react-daterange-picker__calendar--closed {
    display: none;
}
.react-daterange-picker__calendar .react-calendar {
    border-width: thin;
}

.screens-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(1),
.screens-table .rdt_TableCell:nth-child(1) {
    flex: 0 0 20px !important;
    min-width: 20px !important;
}

.screens-table .rdt_TableRow > div:nth-child(2) {
    flex: 0 0 24px !important;
    min-width: 24px !important;
}
.screens-table .rdt_TableHead .rdt_TableHeadRow > div:nth-child(2) {
    flex: 0 0px !important;
    min-width: 8px !important;
    padding: 0 0 0 24px;
}

.rdt_Pagination > div > svg {
    display: none !important;
}

/*
.knJBbt {
min-width: 25px !important;
flex: 0 0 25px !important;
}
.jfkYcq {
  min-width: 390px !important;
}
.dWJTER {
  flex: 0 0 30px !important;
  min-width: 30px !important;
}
.eFyeBY {
  flex: 0 0 30px !important;
  min-width: 30px !important;
}
.dvKCZs{
  flex: 0 0 25px !important;
}
.dhQIga {
  padding-left: 10px !important;
} */

/* #pagination-first-page {
  visibility: hidden !important;
}
#pagination-last-page {
  visibility: hidden !important;
} */

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: #006edc;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

/** end Date Range Picker **/

/** Fix DSP-218 **/
.css-b62m3t-container input:focus {
    border: 0 !important;
    box-shadow: none !important;
}

*[debug='true'],
.debug {
    border: 1px dashed magenta !important;
}

.campaignPicker .react-daterange-picker__calendar,
.campaignPicker .react-calendar {
    min-height: 320px !important;
}

.bg-blue-200 {
    background-color: rgb(191 219 254);
}

.bg-blue-600 {
    background-color: rgb(37 99 235);
}

.bg-blue-700 {
    background-color: rgb(29 78 216);
}

.bg-blue-800 {
    background-color: rgb(30 64 175);
}

.bg-blue-900 {
    background-color: rgb(30 58 138);
}

.rdt_Table .rdt_TableBody {
    overflow: auto;
}
